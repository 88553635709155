
.pop-up-modal{
    pointer-events: auto;
    position: fixed;
    top: 50%;
    left: 52%;
    transform: translate(-42%, -50%);
    width: 84%;
    height: 101%;
    background-color: transparent;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;  
    will-change: opacity;
}
.pop-up-overlay{
        pointer-events: auto;
        position: fixed;
        top: 0%;
        left: 0%;
        width:100%;
        height: 100%;
        background-color: #000000d6;
        z-index: -1;
}

.pop-up-base-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:50%;
    margin:55px 0;
}
.full-pop-image{
    height: 70vh;
    max-width: 75%;
    object-fit: contain;
}

.fe-w72{
    width: 72%;
}
@media screen and (max-width:800px) {
    .fe-w72{
        width: 100%;
    } 
    .pop-up-modal {
        width: 100%;
        transform: none;
        transform: translate(-50%, 0);
        left:50%;
        top:100px;
       
    }
.full-pop-image{
    height: 44%;
    width: 80%;
}
.pop-up-base-container{
width:80%
}
}